import React, { useEffect, useRef, useState } from "react"

const Planet = ({ name, image, delay }) => {
  const planet = useRef()
  useEffect(() => {
    planet.current?.classList.remove("opacity-0")
    planet.current?.classList.add("opacity-100")
  }, [])
  return (
    <div
      className="flex-start absolute -z-50 flex aspect-square h-full animate-spin-slow items-center"
      style={{
        animationDelay: `${delay}ms`,
      }}
    >
      <div
        className="w-12 animate-spin-slow"
        style={{
          animationDirection: "reverse",
          animationDelay: `${delay}ms`,
        }}
      >
        <img
          ref={planet}
          alt={name}
          src={image}
          className="w-full opacity-0 transition-opacity duration-500"
          style={{
            transform: "skew(45deg, 12deg)",
            transitionDelay: `${delay + 300}ms`,
          }}
        />
      </div>
    </div>
  )
}

export default Planet
