import React from "react"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Button from "../common/utils/actionButton"
import { generateRemarkedText } from "../../common/textToRemark"
import Card from "../common/utils/card"

const Consultant = ({ background, content, link }) => {
  const image = getImage(background.localFile)
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      backgroundColor="#ffffff"
      className="w-full snap-start snap-normal"
      style={{
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
      preserveStackingContext
    >
      <div className="w-full bg-white bg-opacity-50 px-8 md:bg-opacity-30 lg:bg-opacity-20">
        <div className="mx-auto flex max-w-5xl flex-col-reverse items-start justify-start py-16 text-black lg:flex-row">
          <div className="w-full max-w-lg py-20 transition-all duration-500">
            <Card focusable={true} background={"bg-white"}>
              <div className="flex w-full flex-col justify-center space-y-2 p-8">
                <div className="text-3xl font-semibold">
                  {generateRemarkedText(content.dummy, content.title)}
                </div>
                <p>
                  {generateRemarkedText(content.dummy, content.description)}
                </p>
                <Button
                  link={link}
                  title={content.actionButtonLabel}
                  outer={true}
                >
                  {content.actionButtonLabel}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Consultant
