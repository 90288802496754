import React from "react"

const Triangle = ({ background, isSafari, path }) => {
  return (
    <div
      style={{
        background: `rgb(${background}, 0.6)`,
      }}
      className={`absolute top-0 left-0 h-full w-full ${
        isSafari ? "backdrop-blur-sm" : "backdrop-blur-md"
      } ${path}`}
    ></div>
  )
}

export default Triangle
