import React from "react"
import Button from "../common/utils/actionButton"
import Remark from "../common/utils/remark"
import EmvSystem from "./evmSystem"
import Card from "../common/utils/card"
import { generateRemarkedText } from "../../common/textToRemark"

const CallToAction = ({ content }) => {
  return (
    <section className="w-full snap-start snap-normal bg-creation bg-cover bg-fixed bg-top shadow-md">
      <div className="flex w-full items-center bg-white bg-opacity-20 px-8 py-32 md:px-16">
        <div className="mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-16 text-black lg:flex-row lg:justify-between lg:pl-20">
          <div className="w-full translate-y-8 scale-125 transition-transform duration-500 lg:w-max">
            <EmvSystem />
          </div>
          <div className="max-w-lg transform-gpu transition-transform duration-200">
            <div className="w-full scale-100 transition-transform duration-500">
              <Card background="bg-white" padding="p-8" focusable>
                <div className=" flex flex-col justify-center space-y-4">
                  <div className="text-3xl font-semibold">
                    {generateRemarkedText(content.dummy, content.title)}
                  </div>
                  <p>
                    {generateRemarkedText(content.dummy, content.description)}
                  </p>
                  <Button
                    link="#solutions"
                    title={content.actionButtonLabel}
                    local={true}
                  >
                    {content.actionButtonLabel}
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction
