import React from "react"
import ProductCard from "./productCard"

const Solutions = ({ title, content }) => {
  const solutions = content
    .filter(solution => solution.shown)
    .map(solution => ({
      title: solution.name,
      description: solution.excerpt,
      link: solution.released ? `/solutions/${solution.slug}` : undefined,
      image: solution.thumbnail.localFile,
      alt: solution.name,
      ready: solution.released,
    }))

  return (
    <section
      id="solutions"
      className="w-full snap-start snap-normal bg-creation-left bg-cover bg-right-bottom bg-no-repeat md:bg-center lg:bg-contain"
    >
      <div className="flex w-full flex-col items-center justify-center space-y-4 bg-white bg-opacity-20 px-4 py-16 shadow-md md:space-y-0">
        <h1 className="w-full text-center text-5xl">{title}</h1>
        <div className="flex w-full max-w-5xl flex-col items-center justify-center space-y-2 md:space-y-0">
          {solutions.map(solution => (
            <ProductCard {...{ ...solution }} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Solutions
