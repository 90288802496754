import React from "react"

const Stack = () => {
  return (
    <section className="hidden h-40 w-full snap-start snap-normal items-center bg-blue-500 shadow-md">
      Stack
    </section>
  )
}

export default Stack
