import React from "react"

const Service = ({ title, Icon, description }) => {
  return (
    <div className="group flex w-full max-w-sm transform-gpu flex-col items-center justify-center gap-2 duration-500 sm:w-48">
      <span className="h-10 w-10 transform-gpu text-primary duration-300 group-hover:text-sky">
        <Icon />
      </span>
      <h3 className="w-full text-center font-bold">{title}</h3>
      <p className="w-full text-center">{description}</p>
    </div>
  )
}

export default Service
