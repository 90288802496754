import React from "react"
import Remark from "../components/common/utils/remark"

export const generateRemarkedText = (dummy, text) => {
  return (
    <>
      {text
        .split(dummy)
        .map((section, i) =>
          i % 2 === 0 ? <span>{section}</span> : <Remark>{section}</Remark>
        )}
    </>
  )
}
