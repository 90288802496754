import React from "react"
import Triangle from "./triangle"

const Sun = ({ isSafari }) => {
  const triangles = [
    {
      className: "clip-lt",
      color: "240, 205, 194",
      path: "M57.5054 181V135.84L1.64064 103.171L57.5054 181Z",
    },
    {
      className: "clip-lc",
      color: "201, 179, 245",
      path: "M57.6906 181V135.84L113.555 103.171L57.6906 181Z",
    },
    {
      className: "clip-lb",
      color: "136, 170, 241",
      path: "M57.5055 124.615V66.9786L1 92.2811L57.5055 124.615Z",
    },
    {
      className: "clip-rt",
      color: "201, 179, 245",
      path: "M57.6903 124.615V66.9786L114.196 92.2811L57.6903 124.615Z",
    },
    {
      className: "clip-rc",
      color: "240, 205, 194",
      path: "M1.00006 92.2811L57.5054 1V66.9786L1.00006 92.2811Z",
    },
    {
      className: "clip-rb",
      color: "184, 250, 246",
      path: "M114.196 92.2811L57.6906 1V66.9786L114.196 92.2811Z",
    },
  ]
  return (
    <section
      style={{ height: "182px", width: "115px" }}
      className="animate-float relative"
    >
      {triangles.map(triangle => (
        <Triangle
          background={triangle.color}
          path={triangle.className}
          isSafari={isSafari}
        />
      ))}
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 115 182"
        fill="none"
        className="absolute top-0 left-0 h-full w-full"
      >
        {triangles.map(triangle => (
          <path
            stroke="#1616B4"
            stroke-linejoin="round"
            d={triangle.path}
          ></path>
        ))}
      </svg>
    </section>
  )
}

export default Sun
