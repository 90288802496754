import React from "react"
import { graphql } from "gatsby"
import CallToAction from "../components/index/callToAction"
import Consultant from "../components/index/consultant"
import Solutions from "../components/index/solutions"
import Layout from "../components/common/body/layout"
import { HeadHelmet } from "../components/common/body/seo"
import Stack from "../components/index/stack"
import ServiceList from "../components/services/serviceList"
export default function Home({ data: { content } }) {
  return (
    <Layout
      lang={content.node_locale}
      site={content.title}
      solutions={content.solutionList}
      contactLink={content.contactLink}
      strings={content.strings}
      solutionsHeader={content.solutionsHeader}
    >
      <CallToAction content={content.callToAction} />

      <Stack />
      <Solutions
        title={content.solutionsTitle}
        content={content.solutionList}
      />
      <ServiceList />
      <Consultant
        background={content.images[0]}
        content={content.contactInformation}
        link={content.contactLink.link}
      />
    </Layout>
  )
}

export const Head = ({ location, data: { content } }) => {
  return (
    <HeadHelmet
      seo={{
        description: content.description,
      }}
      lang={content.node_locale}
      title={content.title}
      site={content.title}
      location={location.pathname}
      preview={content.twitterImage.localFile.publicURL}
      type="webpage"
    />
  )
}

export const query = graphql`
  {
    content: contentfulPage {
      title
      description
      solutionsHeader
      callToAction {
        dummy
        title
        description
        actionButtonLabel
      }
      solutionsTitle
      solutionList {
        name
        excerpt
        slug
        released
        available
        shown
        thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 320
                blurredOptions: { width: 50 }
              )
            }
          }
        }
        node_locale
      }
      contactInformation {
        dummy
        title
        description
        actionButtonLabel
      }
      contactLink {
        label
        link
      }
      images {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              blurredOptions: { width: 50 }
            )
          }
        }
      }
      strings {
        comingSoon
        seeMore
        goHome
        copyright
        openMenu
        about {
          link
          label
        }
      }
      twitterImage {
        localFile {
          publicURL
        }
      }
      node_locale
    }
  }
`
