import React, { useEffect, useState } from "react"
import Sun from "./sun"
import Planet from "./planet"
import avax from "../../../images/chains/avax.svg"
import bnb from "../../../images/chains/bnb.svg"
import cro from "../../../images/chains/cro.svg"
import eth from "../../../images/chains/eth.svg"
import ftm from "../../../images/chains/ftm.svg"
import matic from "../../../images/chains/matic.svg"

const EmvSystem = () => {
  const [isSafari, setIsSafari] = useState()
  const chains = [
    { image: avax, name: "Avalanche logo" },
    { image: bnb, name: "BNB Chain Logo" },
    { image: cro, name: "Cronos logo" },
    { image: eth, name: "Ethereum logo" },
    { image: ftm, name: "Fantom logo" },
    { image: matic, name: "Polygon logo" },
  ]

  useEffect(() => {
    if (
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor)
    )
      setIsSafari(true)
  }, [])

  return (
    <div
      className="relative flex items-center justify-center p-4"
      style={{
        transform: "skew(-45deg, -12deg)",
      }}
    >
      <div
        style={{
          transform: "skew(45deg, 12deg)",
        }}
      >
        <div
          className={`z-50  pb-8 md:-translate-x-4 ${
            isSafari ? "scale-[1.75]" : "-translate-y-16 scale-125"
          }`}
        >
          <Sun isSafari={isSafari} />
        </div>
      </div>
      {!isSafari &&
        chains.map((chain, i) => (
          <Planet delay={i * 600} image={chain.image} name={chain.name} />
        ))}
    </div>
  )
}

export default EmvSystem
