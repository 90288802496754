import React from "react"
import Wallet from "../../images/icons/wallet"
import Custom from "../../images/icons/custom"
import Dumbbell from "../../images/icons/dumbbell"
import Receipt from "../../images/icons/receipt"
import Shield from "../../images/icons/shield"
import Target from "../../images/icons/target"
import Usercheck from "../../images/icons/usercheck"
import Windows from "../../images/icons/windows"
import Code from "../../images/icons/code"
import Service from "./service"

const Services = () => {
  const services = [
    {
      type: "Auditing",
      list: [
        {
          Icon: Shield,
          title: "Security audits",
          description:
            "Don't let attackers through the door. Let's be proactive and examine your infrastructure in detail",
        },
        {
          Icon: Receipt,
          title: "Smart contract audits",
          description:
            "Losing liquidity should not be an option. Let's analyze your contracts and find those bugs before launch",
        },
        {
          Icon: Usercheck,
          title: "Consultancy",
          description:
            "Plan or upgrade the quality, scalability and security of your solutions, with experts",
        },
      ],
    },
    {
      type: "Development",
      list: [
        {
          Icon: Custom,
          title: "Custom contracts",
          description:
            "We will deeply understand your problem and develop a tailored codebase, for you",
        },
        {
          Icon: Windows,
          title: "dApps",
          description:
            "Take full advantage of the infrastructure blockchain provides",
        },
        {
          Icon: Wallet,
          title: "Web3 as payment gateway",
          description:
            "Avoid the huge credit card fees by accepting crypto on your site",
        },
        {
          Icon: Target,
          title: "Tracking bots",
          description:
            "Make optimal decisions with real-time blockchain transaction data",
        },
      ],
    },
    {
      type: "Education",
      list: [
        {
          Icon: Dumbbell,
          title: "Enterprise training",
          description:
            "Get valuable insights on blockchain and how companies are now getting benefit from it",
        },
        {
          Icon: Code,
          title: "Bootcamps",
          description:
            "Learn or improve your skills with live and practical coding sessions",
        },
      ],
    },
  ]
  return (
    <section className="flex w-full snap-start flex-col gap-5 py-12 shadow-md">
      <h1 className="text-center text-5xl ">Services</h1>
      <div className="flex flex-col items-center gap-8">
        {services.map(({ type, list }, i) => (
          <div
            className={`flex w-full flex-col items-center justify-center gap-8 px-4 py-6 ${
              i % 2 ? "bg-gray-50" : ""
            }`}
          >
            <h2 className="text-xl">{type}</h2>
            <div className="flex w-full max-w-full flex-col flex-wrap items-center justify-center gap-8 sm:flex-row sm:items-start">
              {list.map(props => (
                <Service {...props} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Services
