import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../common/utils/actionButton"
import Card from "../common/utils/card"

const ProductCard = ({ title, description, image, alt, link, ready }) => {
  return (
    <section className="flex w-full flex-col items-center space-y-10 py-4 text-gray-800 md:flex-row md:odd:space-x-16 md:even:flex-row-reverse lg:space-y-4">
      {/**Text */}
      <div className="relative my-auto w-4/5 md:w-3/5">
        <Card background="card-background" padding="p-4" focusable pinging>
          <div className="flex flex-col">
            <div className="w-full border-b border-primary pb-4 text-lg font-semibold">
              {title}
            </div>
            <div className="py-4">{description}</div>
            <Button link={link} title={`Learn more about ${title}`}>
              {ready ? "See more!" : "Coming soon!"}
            </Button>
          </div>
        </Card>
      </div>
      {/**Picture */}
      <div className="flex w-3/5 max-w-xs items-center justify-center rounded-md transition-all duration-500 md:w-2/5 md:even:mr-16">
        <GatsbyImage
          image={getImage(image)}
          className="flex justify-center object-contain"
          imgClassName="w-full mx-auto"
          alt={alt}
        />
      </div>
    </section>
  )
}

export default ProductCard
